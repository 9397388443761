import { cleanPhone } from 'utils/form/normalizations';
import { get } from 'utils/api/fetch-client';
import _ from 'lodash';
import moment from 'moment';
import { decode } from 'html-entities';

export function memoize(fn) {
  if (!fn.cache) fn.cache = {}; // eslint-disable-line no-param-reassign

  return (options) => {
    const key = JSON.stringify(options);

    if (fn.cache.hasOwnProperty(key)) return fn.cache[key];
    fn.cache[key] = fn(options); // eslint-disable-line no-param-reassign

    return fn.cache[key];
  };
}

export function trunc(string, maxLength) {
  if (string.length > maxLength) {
    const trimmedString = string.substr(0, maxLength);

    return `${trimmedString.substr(0, Math.min(trimmedString.length, trimmedString.lastIndexOf(' ')))} ...`;
  }

  return string;
}

export function stripTags(string) {
  return string?.length ? string.replace(/(<([^>]+)>)/gi, '') : string;
}

export function capitalize(string) {
  return string.charAt(0).toUpperCase() + string.slice(1);
}

export function toSnakeCase(string) {
  return string.replace(/([^^])([A-Z])/g, '$1_$2').toLowerCase();
}

export function documentTemplateVarsToSnakeCase(string) {
  return string.replace(/[A-Z]|\d+/g, c => `_${c.toLowerCase()}`);
}

export function splitParams(filterParams) {
  return _.fromPairs(filterParams.split('&').map(s => s.split('=')));
}

export function filteredElementsByJob(filterParams, elementsToFilter) {
  let idsSelected = [];
  if (filterParams) {
    const params = splitParams(filterParams);
    if (params.job_ids) {
      idsSelected = parseArrayObj(unescape(params.job_ids), []).map(id => parseInt(id.value, 10));
    }
  }

  const showedElements = idsSelected.length ?
    [] : elementsToFilter;
  const moreElements = [];

  if (idsSelected.length) {
    elementsToFilter.forEach((element) => {
      const attribute = _.has(element, 'jobId') ? 'jobId' : 'job.id';
      idsSelected.includes(+_.get(element, attribute)) ?
        showedElements.push(element) : moreElements.push(element);
    });
  }

  return { showedElements, moreElements };
}

export function groupBy(arrayOfObjects, key) {
  return arrayOfObjects.reduce((groups, item) => {
    const val = item[key];
    groups[val] = groups[val] || []; // eslint-disable-line no-param-reassign
    groups[val].push(item);

    return groups;
  }, {});
}

export function encodeQueryData(data) {
  const ret = [];
  // eslint-disable-next-line no-restricted-syntax
  for (const d in data) {
    if (typeof data[d] === 'object' && !Array.isArray(data[d])) {
      ret.push(`${encodeURIComponent(d)}=${encodeURIComponent(JSON.stringify(data[d]))}`);
    } else if (Array.isArray(data[d])) {
      const j = data[d].map(e => JSON.stringify(e));
      ret.push(`${encodeURIComponent(d)}=${encodeURIComponent(j.join('____'))}`);
    } else {
      ret.push(`${encodeURIComponent(d)}=${encodeURIComponent(data[d])}`);
    }
  }
  return ret.join('&');
}

export const parseStructureObject = (v, def = null) => {
  if (v === undefined || v === '' || v === null) return def;
  const obj = _.isObject(v) ? v : JSON.parse(v);
  return _.filter(obj, o => (_.isNumber(o) ? _.size(_.toString(o)) : _.size(o))).length > 0 ? obj : def;
};

export const parseCustomFields = (v, def) => {
  if (v === undefined || v === '' || v === null) return def;
  const obj = _.isObject(v) ? v : JSON.parse(v);
  const data = _.pickBy(obj, (o) => {
    return parseStructureObject(o) !== null;
  });
  return Object.keys(data).length > 0 ? data : def;
};
export const parseArrayString = (v, def) => {
  if (v === undefined) return def;
  const value = typeof v === 'string' ? v.replace(/["]/g, '').split('____') : v;
  return value;
};
export const parseArrayNum = (v, def) => {
  if (v === undefined) return def;
  const value = typeof v === 'string' ? v.split('____') : v;
  return value.map(x => +x);
};
export const parseBoolean = (v, def) => (v === undefined ? def : (v === 'true'));
export const parseArrayObj = (v, def) => {
  if (v === undefined) return def;

  let value = v;
  if (typeof v === 'string') {
    value = v.replace(/{(?:[^{}]*|{[^{}]*})*}/g, match => match.replace(/____/g, ' ')).split('____');
  };
  value = _.without(value.map(x => parseStructureObject(x, null)), undefined, null, '');
  return value.length ? value : def;
};
export const parseString = (v, def) => (v === undefined ? def : v);
export const parseNumber = (v, def) => (v === undefined ? def : +v);
export const parseObj = (v, def) => {
  if (v === undefined) return def;
  const value = typeof v === 'string' ? JSON.parse(v) : v;
  return value;
};

export function difference(object, base) {
  return _.transform(object, (result, value, key) => {
    if (_.isArray(base[key]) && base[key].length) {
      // eslint-disable-next-line no-param-reassign
      if (!_.isEqual(value, base[key])) result[key] = value;
    } else if (!_.isEqual(value, base[key])) {
      // eslint-disable-next-line no-param-reassign
      result[key] = (_.isObject(value) && _.isObject(base[key])) ?
        difference(value, base[key]) : value;
    }
  });
}

export function statusClass(status) {
  switch (status) {
    case 'draft':
      return 'is-grey';
    case 'activated':
    case 'completed':
      return 'is-emerald';
    case 'closed':
    case 'cancelled':
      return 'is-red';
    case 'internal':
      return 'is-yellow';
    case 'confirmed':
      return 'is-blue';
    default:
      return '';
  }
}

export function statusIcon(status) {
  switch (status) {
    case 'error':
      return 'huge-cancel-circle';
    case 'in-process':
      return 'huge-loading-03';
    case 'scheduled':
      return 'huge-clock-01';
    case 'expired':
      return 'huge-alert-02';
    case 'closed':
      return 'huge-remove-circle';
    case 'success':
    case 'published':
      return 'huge-checkmark-circle-02';
    case 'on-review':
      return 'huge-help-circle';
    case 'needs-review':
      return 'huge-alert-02';
    default:
      return undefined;
  }
}

export function mentionsConfig(mentions) {
  return [{
    feed: (opts, callback) => {
      const matchProperty = 'id';
      let data = mentions.filter(function (item) {
        return item[matchProperty].indexOf(opts.query.toLowerCase()) == 0;
      });
      data = data.sort(function (a, b) {
        return a[matchProperty].localeCompare(b[matchProperty], undefined, { sensitivity: 'accent' });
      });
      callback(data);
    },
    itemTemplate: '<li data-id="{id}"><img class="photo" src="{image}" /><strong class="username">{id}</strong><span class="fullname">{fullname}</span></li>',
    outputTemplate: '@{id}',
    minChars: 0
  }];
}

export function candidateFormIcon(status) {
  const statuses = {
    sent: 'huge-file-01',
    expired: 'huge-file-remove',
    completed: 'huge-file-verified',
    uncomplete: 'huge-file-01',
    blocked: 'huge-file-minus',
  };

  return (statuses[status] || 'huge-file-01');
}

export function videoInterviewIcon(status) {
  const statuses = {
    sent: 'huge-video-01',
    sending: 'huge-video-01',
    pending: 'huge-video-01',
    completed: 'huge-video-01',
    expired: 'huge-video-off',
    blocked: 'huge-video-01',
    in_process: 'huge-video-01',
    uncomplete: 'huge-video-01',
    resend: 'huge-video-01',
  };

  return (statuses[status] || 'huge-video-01');
}

export function messageChannel(channel) {
  const channels = {
    whatsapp: "huge-whatsapp",
    email: "huge-mail-01"
  };

  return channels[channel]
}

export function getUrlParams(urlParams = window.location.search) {
  if (!urlParams) return {};
  return urlParams
    .match(/([^?&]+)=([^&]+)/g)
    .reduce((params, param) => {
      const [key, value] = param.split('=');
      params[key] = window.decodeURIComponent(value.replace(/\+/g, ' ')); // eslint-disable-line no-param-reassign

      return params;
    }, {});
}

export function defaultColor(color) {
  return color === 'default' ? 'blue' : color;
}

export function jobStatusColor(color) {
  switch (color) {
    case 'draft':
      return 'grey';
    case 'activated':
      return 'emerald';
    case 'internal':
      return 'yellow';
    case 'closed':
      return 'red';
    default:
      return undefined;
  }
}

export function internationalPhoneFormat(countryCodes, phone, countryCode) {
  if (countryCode === undefined || countryCode === null) return cleanPhone(phone);

  const phoneWithoutSpaces = phone.trim();

  if (phoneWithoutSpaces.startsWith('+')) return cleanPhone(phone);

  const formatedCountryCode = countryCode.split('_')[0].toLowerCase();
  const callingCode = countryCodes[formatedCountryCode];

  if (callingCode === undefined || callingCode === null) return cleanPhone(phone);

  if (phoneWithoutSpaces.startsWith(callingCode)) {
    const phoneWithoutCallingCode = phone.replace(callingCode, '');
    return `${callingCode} ${cleanPhone(phoneWithoutCallingCode)}`;
  }

  return `${callingCode} ${cleanPhone(phone)}`;
}

export function loadSuggestedLocationOptions(input, countryUid = null) {
  if (input.length < 3) return Promise.resolve([]);

  const url = Routes.locations_suggest_path({ q: input, country: countryUid });

  return get(url).then(data => (
    data.locations.map(location => (
      { value: location.uid, label: location.suggest_route }
    ))
  ));
}

export function deviceDetect() {
  const userAgent = typeof window.navigator === 'undefined' ? '' : navigator.userAgent;
  const isMobile = Boolean(userAgent.match(/Android|BlackBerry|iPhone|iPad|iPod|Opera Mini|IEMobile|WPDesktop/i));

  return isMobile;
}

export function equalArrays(a, b) {
  const orderedA = a.slice().sort();
  const orderedB = b.slice().sort();

  return (JSON.stringify(orderedA) === JSON.stringify(orderedB));
}

export function hex2rgb(hex) {
  const validHEXInput = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
  if (!validHEXInput) {
    return false;
  }
  return `${parseInt(validHEXInput[1], 16)}, ${parseInt(validHEXInput[2], 16)}, ${parseInt(validHEXInput[3], 16)}`;
}

export function youtubeUrlMatching(url) {
  const regexYoutube = /(?:youtube(?:-nocookie)?\.com\/(?:[^/\n\s]+\/\S+\/|(?:v|e(?:mbed)?)\/|\S*?[?&]v=)|youtu\.be\/)([a-zA-Z0-9_-]{1,})/;
  return url.match(regexYoutube);
}

export function vimeoUrlMatching(url) {
  // eslint-disable-next-line no-useless-escape
  const regexVimeo = /(?:www\.|player\.)?vimeo.com\/(?:channels\/(?:\w+\/)?|groups\/(?:[^\/]*)\/videos\/|album\/(?:\d+)\/video\/|video\/|)(\d+)(?:[a-zA-Z0-9_\-]+)?/i;
  return url.match(regexVimeo);
}

export const selectRelativeTimeUnit = (date) => {
  if (moment().diff(date, 'years')) return  {unit: 'year', value: -moment().diff(date, 'years')}
  if (moment().diff(date, 'months')) return  {unit: 'month', value: -moment().diff(date, 'months')}
  if (moment().diff(date, 'days')) return  {unit: 'day', value: -moment().diff(date, 'days')}
  if (moment().diff(date, 'hours')) return  {unit: 'hour', value: -moment().diff(date, 'hours')}
  if (moment().diff(date, 'minutes')) return  {unit: 'minute', value: -moment().diff(date, 'minutes')}
  return {unit: 'second', value: -moment().diff(date, 'seconds')}
}

export const transformTextToWhatsappFormat = (text) => {
  if (!text) return '';

  const formattedText = text.replace(/<\/?p>/g, '\n')
                            .replace(/<br \/?>/g, '\n')
                            .replace(/<\/?strong>/g, '*')
                            .replace(/<\/?em>/g, '_')
                            .replace(/<\/?s>/g, '~')
                            .replace(/<\/?tt>/g, '```');

  return encodeURIComponent(decode(formattedText));
};

export const customStyles = {
  option: (optionDefaultStyles, optionState) => ({
    ...optionDefaultStyles,
    // eslint-disable-next-line no-nested-ternary
    color: optionState.isSelected ? '#333' : optionState.isFocused ? '#333' : '#666',
    // eslint-disable-next-line no-nested-ternary
    backgroundColor: optionState.isDisabled ? '#f3f5f6' : optionState.isSelected ? '#f5faff' : optionState.isFocused ? '#f2f9fc' : '#fff',
    borderColor: optionState.isFocused ? '#0099e6' : '#d4d4d4',
  }),
  control: (controlDefaultStyles, controlState) => ({
    ...controlDefaultStyles,
    color: controlState.isDisabled ? '#a1a1a1' : '#333',
    backgroundColor: controlState.isDisabled ? '#f3f5f6' : '#fff',
    borderColor: controlState.isDisabled ? '#d4d4d4' : controlState.isFocused ? '#667085' : '#E6E5E5',
    '&:hover': { borderColor: '#667085' },
    borderRadius: '12px',
    minHeight: '48px',
  }),
};
